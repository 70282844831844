<template>
  <b-card
      class="punishorder-edit-wrapper"
  >
    <!-- form -->
    <b-form id="punishorderForm" class="edit-form mt-2">
      <b-row>
        <!--  基本信息 -->
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>
        <!--仓库名称-->
        <b-col md="4">
          <modal-select
              label="仓库名称 *"
              type="input"
              v-on:change="fromChildren($event,['warehouse_name','warehouse_id'])"
              :params="['warehouse_name','warehouse_id']"
              modalName="仓库"
              placeholder="点击选择仓库"
              :is-readonly="isView"
              v-model="punishorder.warehouse_name"
          >
          </modal-select>
        </b-col>
        <!--店铺名称-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="店铺名称"
              label-for="store"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="store_id"
                :options=store_options
                v-model="punishorder.store"
                @input="changeStore($event)"
                class="select-size-sm"
                placeholder="请选择店铺"
                :disabled="isView"
            />
          </b-form-group>
        </b-col>
        <!--平台-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="平台"
              label-for="company_name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="company_name"
                size="sm"
                readonly
                v-model="punishorder.company_name"
            />
          </b-form-group>
        </b-col>
        <!--省份-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="省份"
              label-for="province_id"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="province_id"
                :options="province_options"
                v-model="punishorder.province"
                @input="changeProvince($event)"
                class="select-size-sm"
                placeholder="请选择省份"
                :disabled="isView"
            />
          </b-form-group>
        </b-col>
        <!--城市-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="城市"
              label-for="city_id"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="city_id"
                :options="city_options"
                :disabled="is_disabled"
                v-model="city"
                class="select-size-sm"
                placeholder="请选择城市"
            />
          </b-form-group>
        </b-col>
        <!--运营人-->
        <b-col md="4">
          <modal-select
              label="运营人 *"
              type="input"
              v-on:change="fromChildren($event,['business_name','business_id'])"
              :params="['business_name','business_id']"
              userDepartment="operator"
              modalName="责任人"
              placeholder="点击选择运营人"
              :is-readonly="isView"
              v-model="punishorder.business_name"
          >
          </modal-select>
        </b-col>
        <!--发生日期-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="发生日期"
              label-for="happen_date"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                id="happen_date"
                :disabled="isView"
                v-model="punishorder.happen_date"
                class="form-control"
            />
          </b-form-group>
        </b-col>
        <!--罚款信息 -->
        <b-col md="12">
          <div class="inner-card-title">罚款信息</div>
        </b-col>
        <!--罚款单号-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="罚款单号"
              label-for="punish_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="punish_no"
                size="sm"
                readonly
                v-model="punishorder.punish_no"
            />
          </b-form-group>
        </b-col>
        <!--罚款产品id-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="罚款产品ID"
              label-for="out_product_id"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="out_product_id"
                size="sm"
                :readonly="isView"
                v-model="punishorder.out_product_id"
            />
          </b-form-group>
        </b-col>
        <!--商品名称-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="商品名称"
              label-for="out_product_name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="out_product_name"
                size="sm"
                :readonly="isView"
                v-model="punishorder.out_product_name"
            />
          </b-form-group>
        </b-col>
        <!--罚款金额-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="罚款金额"
              label-for="amount"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="amount"
                type="number"
                size="sm"
                :readonly="isView"
                v-model="punishorder.amount"
            />
          </b-form-group>
        </b-col>
        <!--扣款方式-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="扣款方式"
              label-for="punish_method"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="punish_method"
                :options="getCodeOptions('punish_method')"
                v-model="punishorder.punish_method"
                :disabled="isView"
                :reduce="option => option.value"
                class="select-size-sm"
                placeholder="请选择扣款方式"
            />
          </b-form-group>
        </b-col>
        <!--扣款原因-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="扣款原因"
              label-for="punish_reason"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="punish_reason"
                :options="getCodeOptions('punish_reason')"
                v-model="punishorder.punish_reason"
                class="select-size-sm"
                :disabled="isView"
                placeholder="请选择扣款原因"
            />
          </b-form-group>
        </b-col>
        <!--罚款日期-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="罚款日期"
              label-for="punish_date"
              label-size="sm"
              class="mb-1"
          >
            <flat-pickr
                id="punish_date"
                v-model="punishorder.punish_date"
                :disabled="isView"
                class="form-control"
            />
          </b-form-group>
        </b-col>
        <!--处理进度-->
        <b-col md="4" v-if="isView">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="处理进度"
              label-for="status"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="status"
                :options="getCodeOptions('punish_status')"
                v-model="punishorder.status"
                :disabled="isView"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <!--处理结果-->
        <b-col md="4" v-if="isView">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="处理结果"
              label-for="deal_result"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="deal_result"
                :options="getCodeOptions('punish_deal_result')"
                v-model="punishorder.deal_result"
                :disabled="isView"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <!--罚款单处理结果备注-->
        <b-col md="12" v-if="isView">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="罚款单处理结果备注"
              label-for="deal_remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="deal_remark"
                size="sm"
                v-model="punishorder.deal_remark"
                rows="3"
                max-rows="6"
                :readonly="isView"
            />
          </b-form-group>
        </b-col>
        <!--其他信息 -->
        <b-col md="12">
          <div class="inner-card-title">其他信息</div>
        </b-col>
        <!--备注-->
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="备注"
              label-for="remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="punishorder.remark"
                rows="3"
                max-rows="6"
                :readonly="isView"
            />
          </b-form-group>
        </b-col>
        <!--罚款单相关附件-->
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="罚款单相关附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1 required"
          >
            <attachment-upload v-if="punishorder.loaded" :theme="'files'"
                               attachment_id="attachments1"
                               :id="punishorder.attachments1"
                               object_type="punishorder"
                               :object_id="punishorder.load_id"
                               @change="onUploaded"
                               :readonly="isView"
            />
          </b-form-group>
        </b-col>
        <!--申诉中相关附件-->
        <b-col md="12" v-if="isView || isAppeal">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="申诉中相关附件"
              label-for="attachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload v-if="punishorder.loaded" :theme="'files'"
                               attachment_id="attachments2"
                               :id="punishorder.attachments2"
                               object_type="punishorder"
                               :object_id="punishorder.load_id"
                               @change="onUploaded"
                               :readonly="isView"
            />
          </b-form-group>
        </b-col>
        <!--已处理相关附件-->
        <b-col md="12" v-if="isView">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="已处理相关附件"
              label-for="attachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload v-if="punishorder.loaded" :theme="'files'"
                               attachment_id="attachments3"
                               :id="punishorder.attachments3"
                               object_type="punishorder"
                               :object_id="punishorder.load_id"
                               @change="onUploaded"
                               :readonly="isView"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-50">
          <b-button v-if="!isView" variant="primary" class="mr-1" @click="save">保存</b-button>
          <b-button variant="outline-secondary" @click="cancel">取消</b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    <!--  loading  -->
    <div v-show="loadingModal"><xy-loading :placeholder="loadingPlaceholder"></xy-loading>  </div>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import punishorderStore from './punishorderStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty, second} from '@core/utils/filter'
import ModalSelect from "@/views/components/modal/ModalSelect";
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import storeStore from "@/views/apps/store/storeStore";
import areaStore from "@/views/apps/area/areaStore";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import XyLoading from "@/views/components/xy/XyLoading";


export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    ModalSelect,
    AttachmentUpload,
    XyLoading,
  },
  data() {
    return {
      id: ref(0),
      punishorder: ref({}),
      store_options: [],
      province_options: [],
      city_options: [],
      is_disabled: true,
      city: null,
      isView: false,
      isAppeal: false,
      loadingModal:false,
      loadingPlaceholder:"单据正在生成,请勿重复操作!"
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('punishorder')) store.registerModule('punishorder', punishorderStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('area')) store.registerModule('area', areaStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('punishorder')) store.unregisterModule('punishorder')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('area')) store.unregisterModule('area')
    })

    const edit = function () {
      this.getProvinceOptions()

      store.dispatch('punishorder/edit', {id: this.id}).then(res => {
        this.punishorder = res.data.data
        if (this.id != 0) {
          this.punishorder.store = {label: this.punishorder.store_name, value: this.punishorder.store_id}
          this.getStoreOptions(this.punishorder.warehouse_id)
          this.punishorder.province = {label: this.punishorder.province_name, value: this.punishorder.province_id}
          this.city = {label: this.punishorder.city_name, value: this.punishorder.city_id}
          this.getCityOptions(this.punishorder.province_id)
          this.is_disabled = this.isView
          this.punishorder.happen_date = toDate(this.punishorder.happen_date)
          this.punishorder.punish_method = getCode('punish_method', this.punishorder.punish_method)
          this.punishorder.punish_reason = getCode('punish_reason', this.punishorder.punish_reason)
          if (this.punishorder.status === 2) this.isAppeal = true
          this.punishorder.status = getCode('punish_status', this.punishorder.status)
          this.punishorder.deal_result = getCode('punish_deal_result', this.punishorder.deal_result)
        }
          this.punishorder.punish_date = toDate(this.punishorder.punish_date)
      })
    }

    const view = function () {
      store.dispatch('punishorder/view', {id: this.id}).then(res => {
        this.punishorder = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      let flag = true
      if (isEmpty(this.punishorder.warehouse_id)) {
        toast.error('请选择 仓库')
        flag = false
      }
      if (isEmpty(this.punishorder.store)) {
        toast.error('请选择 店铺')
        flag = false
      }
      if (isEmpty(this.punishorder.company_name)) {
        toast.error('平台 不能为空')
        flag = false
      }
      if (isEmpty(this.punishorder.province)) {
        toast.error('请选择 省份')
        flag = false
      }
      if (isEmpty(this.city)) {
        toast.error('请选择 城市')
        flag = false
      }
      if (isEmpty(this.punishorder.business_name)) {
        toast.error('请选择 运营人')
        flag = false
      }
      if (isEmpty(this.punishorder.happen_date)) {
        toast.error('请选择 发生日期')
        flag = false
      }
      if (isEmpty(this.punishorder.out_product_id)) {
        toast.error('请填写 罚款产品ID')
        flag = false
      }
      if (isEmpty(this.punishorder.out_product_name)) {
        toast.error('请填写 商品名称')
        flag = false
      }
      if (isEmpty(this.punishorder.punish_method)) {
        toast.error('请选择 扣款方式')
        flag = false
      }
      if (isEmpty(this.punishorder.punish_reason)) {
        toast.error('请选择 扣款原因')
        flag = false
      }
      if (isEmpty(this.punishorder.amount)) {
        toast.error('请填写 罚款金额')
        flag = false
      }
      if (this.punishorder.amount < 0) {
        toast.error('罚款金额不能为负')
        flag = false
      }
      if (isEmpty(this.punishorder.attachments1)) {
        toast.error('请上传 罚款单附件')
        flag = false
      }
      if (!flag) return

      this.punishorder.store_id = this.punishorder.store.value
      this.punishorder.store_name = this.punishorder.store.label
      this.punishorder.province_id = this.punishorder.province.value
      this.punishorder.province_name = this.punishorder.province.label
      this.punishorder.city_id = this.city.value
      this.punishorder.city_name = this.city.label
      this.punishorder.punish_method = this.punishorder.punish_method.value
      this.punishorder.punish_reason = this.punishorder.punish_reason.value
      this.punishorder.punish_date = second(this.punishorder.punish_date)
      this.punishorder.happen_date = second(this.punishorder.happen_date)

      this.loadingModal = true
      store.dispatch('punishorder/save', this.punishorder).then(res => {
        toast.success('数据已保存!')
        this.$router.push({name: 'apps-punishorder-list'});
      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.punishorder[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
      //拿到仓库id后查询关联店铺
      if ('warehouse_id' === modal[1]) {
        this.clearFormer()
        //获取新的选项
        this.getStoreOptions(this.punishorder.warehouse_id)
      }
    }

    const getStoreOptions = function (id) {
      store.dispatch('warehouse/findStoreByWarehouseId', {id: id}).then(res => {
        if (!isEmpty(res.data.data)) {
          let res_stores = JSON.parse(res.data.data)
          res_stores.forEach(item => {
            this.store_options.push({label: item.name, value: item.id})
          })
        }
      })
    }

    const changeStore = function (param) {
      if (!isEmpty(param)) {
        //关联平台
        this.changeCompany(param.value)
      } else {
        this.punishorder.store = null
        this.punishorder.company_name = ''
        this.$forceUpdate()
      }

    }

    const changeCompany = function (id) {
      store.dispatch('store/edit', {id: id}).then(res => {
        let store = res.data.data
        if (!isEmpty(store)) {
          this.punishorder.company_id = store.company_id
          this.punishorder.company_name = store.company_name
          this.$forceUpdate()
        }
      })
    }

    const changeProvince = function (param) {
      if (!isEmpty(param)) {
        this.is_disabled = false
        this.city_options = []
        this.getCityOptions(param.value)
      } else {
        this.is_disabled = true
      }
      this.city = null
      this.$forceUpdate()
    }

    const clearFormer = function () {
      //把原来的清空
      this.store_options = []
      this.punishorder.store = null
      this.punishorder.company_name = ''
      this.punishorder.company_id = ''
      this.$forceUpdate()
    }

    const getProvinceOptions = function () {
      store.dispatch('area/getProvince').then(res => {
        let area = res.data.data
        this.province_options = []
        for (let i = 0; i < area.length; i++) {
          this.province_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const getCityOptions = function (parent_id) {
      store.dispatch('area/getCity', {parentId: parent_id}).then(res => {
        let area = res.data.data
        for (let i = 0; i < area.length; i++) {
          this.city_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.punishorder[id] = result
    }


    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      fromChildren,
      changeStore,
      getStoreOptions,
      changeCompany,
      clearFormer,
      getProvinceOptions,
      getCityOptions,
      changeProvince,
      onUploaded,
    }
  }
  ,
  created() {
    this.id = this.$route.query.id || 0;
    this.isView = this.$route.query.isView || false;
    this.edit()
  }
  ,
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
